<template>
  <section class="invoice-preview-wrapper">
    <div class="d-flex justify-content-center mb-1">
      <b-spinner variant="success" label="Spinning" block v-if="loadingInvoice"
        style="width: 3rem; height: 3rem"></b-spinner>
    </div>
    <b-row class="invoice-preview" v-if="!loadingInvoice">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card" id="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <Logo />

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Invoice No
                  <span class="invoice-number">#{{ invoiceId.invoiceNo }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Created On:</p>
                  <p class="invoice-date">
                    {{ beautifyDate(invoiceId.createdOn) }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Due Date:</p>
                  <p class="invoice-date">
                    {{ beautifyDate(invoiceId.dueDate) }}

                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <h6 class="mb-1">Send To:</h6>

                <!-- Selected Client -->
                <div v-if="custId !== 0" class="mt-1">

                  <b-card-text class="mb-25">
                    {{ custId.otherName }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    {{ custId.addressLine1 }}, {{ custId.addressLine2 }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    {{ custId.city }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    {{ custId.tel }}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    {{ custId.email }}
                  </b-card-text>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite responsive :items="invoiceId.invoiceItem" :fields="[
            { key: 'itemName', label: 'Product Name' },
            { key: 'description', label: 'Description' },
            { key: 'quantity', label: 'Quantity' },
            { key: 'price', label: 'Unit Price' },
            { key: 'discount', label: 'Discount' },
            { key: 'cost', label: 'Total Cost' },
          ]">
            <template #cell(description)="data">

              {{ data.item.description }}

            </template>
            <template #cell(price)="data"> Rs {{ data.item.cost.toFixed(2) }} </template>
                <template #cell(cost)="data">
                Rs {{ ((data.item.cost * data.item.quantity) - (data.item.discount === null ? 0 : data.item.discount)).toFixed(2) }}
              </template>
            <template #cell(discount)="data">
              Rs {{ (data.item.discount === null ? 0 : data.item.discount).toFixed(2) }}
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Salesperson:</span>
                  <span class="ml-75">{{ this.genSP }} </span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Subtotal:</p>
                    <p class="invoice-total-amount">    Rs {{ parseFloat(invoiceId.subTotal).toFixed(2) }}</p>
                  </div>

                  <div class="invoice-total-item">
                    <p class="invoice-total-title">VAT:</p>
                    <p class="invoice-total-amount">Rs {{ parseFloat(invoiceId.tax).toFixed(2) }}</p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Discount:</p>
                    <p class="invoice-total-amount">Rs {{ invoiceId.discount === null ? 0 : invoiceId.discount }}</p>
                  </div>

                  <hr class="my-50" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Total:</p>
                    <p class="invoice-total-amount">Rs {{ invoiceId.total.toFixed(2) }}</p>
                  </div>
                  <div class="invoice-total-item" v-if="payments.totalPaid > 0">
                    <p class="invoice-total-title">Total Paid:</p>
                    <p class="invoice-total-amount">Rs {{ payments.totalPaid.toFixed(2) }}</p>
                  </div>
                  <div class="invoice-total-item" v-if="payments.totalPaid > 0">
                    <p class="invoice-total-title">Balance:</p>
                    <p class="invoice-total-amount">Rs {{ payments.balance.toFixed(2) }}</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>{{ invoiceId.notes }}</span>
          </b-card-body>
          <hr class="invoice-spacing" />
          <b-card title="Payments">
            <b-table responsive class="table-payment" v-if="payments.payments.length > 0" :items="payments.payments"
              :fields="[
                { key: 'settlementDate', label: 'Settlement Date' },
                { key: 'amountPaid', label: 'Amount Paid' },
              ]">
              <template #cell(Delete)="data">
                <b-button @click="deletePayment(data.id)" variant="outline-danger">
                  <feather-icon icon="DeleteIcon" />
                </b-button>
              </template>

              <template #cell(amountPaid)="data">
                Rs {{ data.item.amountPaid.toFixed(2) }}
              </template>
            </b-table>
          </b-card>
          <b-card title="Delivery Notes">
            <b-table responsive class="table-delivery" :items="deliverynotes" :fields="[
              { key: 'product', label: 'Product' },
              { key: 'deliveryDate', label: 'Delivery Date' },
              { key: 'note', label: 'Note' },
            ]">
              <template #cell(product)="data">
                <span> {{ getProductName(data.item.itemId) }}</span>
              </template>
            </b-table>
          </b-card>
          <div class="row mx-2">
            <div class="col-4">
              <h4 class="mb-4 text-center">Received By:</h4>

              <input type="text" id="signature" />
            </div>
            <div class="col-4">
              <h4 class="mb-4 text-center">Signature:</h4>

              <input type="text" id="signature" />
            </div>
            <div class="col-4">
              <h4 class="mb-4 text-center">Date:</h4>

              <input type="text" id="signature" />
            </div>
          </div>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: Print -->
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mb-75" block
            @click="printInvoice">
            Print / Download
          </b-button>

          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="mb-75" v-b-modal.modal-payment
            block v-if="payments.balance != 0">
            Add Payment
          </b-button>

          <!-- Button: Add Delivery Note -->
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="mb-75" v-b-modal.modal-delivery
            block>
            Add Delivery
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="mb-75" block
            @click="CompleteInvoice" v-if="payments.balance === 0 && invoiceId.status !== 2">
            Complete
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="modal-delivery" centered title="Add Delivery Note" hide-footer>
      <b-form @submit.prevent="addDelivery">
        <b-row>
          <!-- username -->
          <b-col cols="12">
            <b-form-group label="Delivery Date" label-for="h-Date">
              <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a date" required v-model="deliveryDate"
                local="en" :initial-date="currentDate()" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Product" label-for="h-Date">
              <b-form-select id="h-Date" v-model="deliveryProduct" :options="products" required />
            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col cols="12">
            <b-form-group label="Delivery Note" label-for="d-Amount">
              <b-form-textarea id="d-Amount" v-model="deliveryNote" placeholder="Delivery Note" required />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button variant="primary" type="submit">
              <b-icon icon="plus-circle"></b-icon>
              Add Delivery Note <b-spinner v-if="loadingNote"> </b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!--- Modal Add Payment -->
    <b-modal id="modal-payment" centered title="Add Payment" hide-footer>
      <b-form @submit.prevent="addPayment">
        <b-row>
          <!-- username -->
          <b-col cols="12">
            <b-form-group label="Settlement Date" label-for="h-Date">
              <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a date" required v-model="paymentDate"
                local="en" :initial-date="currentDate()" />
            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col cols="12">
            <b-form-group label="Payment Amount" label-for="d-Amount">
              <b-form-input id="amount" placeholder="Rs" type="number" required min="0" step=".01"
                v-model="paymentAmount" />
              <span v-if="paymentAmount > payments.balance" class="text-danger">Error: Payment amount is greater than
                payment balance</span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button variant="primary" type="submit">
              <b-icon icon="plus-circle"></b-icon>
              Add Payment <b-spinner v-if="loading"> </b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BModal,
  BCol,
  BCard,
  BCardBody,
  BFormSelect,
  BTable,
  BTableLite,
  BCardText,
  BFormGroup,
  BIcon,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormDatepicker,
  BButton,
  BAlert,
  BSpinner,
  BFormTextarea,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import { mapActions, mapGetters } from "vuex";
import html2pdf from "html2pdf.js";
import Ripple from "vue-ripple-directive";
export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BIcon,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BTable,
    BFormDatepicker,
    BForm,
    BFormTextarea,
    BFormSelect,
    BCard,
    BCardBody,
    BModal,
    BTableLite,
    BCardText,
    BButton,
    BSpinner,
    BAlert,
    Logo,
    BLink,
  },
  data() {
    return {
      id: this.$route.params.id,
      salesPersonName: "",
      paymentAmount: "",
      paymentDate: "",
      deliveryDate: "",
      deliveryNote: "",
      deliveryProduct: "",
      products: [],
      genSp: "",
    };
  },
  computed: {
    errorMessage() {
      if (this.paymentAmount > this.payments.balance) {
        return "Error: Payment amount is greater than payment balance";
      } else {
        return "";
      }
    },
    ...mapGetters("invoiceModule", {
      invoiceId: "invoiceId",
      loadingInvoice: "loading",
    }),
    ...mapGetters("productModule", {
      productList: "product",
      loading: "loading",
    }),
    ...mapGetters("customerModule", {
      custId: "custId",
      loading: "loading",
    }),
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
    ...mapGetters("deliveryModule", {
      deliverynotes: "deliverynotes",
      loadingNote: "loading",
    }),

    ...mapGetters("paymentModule", {
      payments: "payments",
      loading: "loading",
    }),
  },
  methods: {
    beautifyDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      return date.toLocaleDateString('en-US', options);
    },
    getDisable() {
      if (this.paymentAmount > this.payments.balance) {
        return true;
      } else {
        return false;
      }
    },
    ...mapActions("invoiceModule", ["getInvoiceIdAction", "updateInvoicesAction"]),
    ...mapActions("customerModule", ["getCustomersByIDAction"]),
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    ...mapActions("paymentModule", ["getPaymentByIDAction", "addPaymentAction"]),
    ...mapActions("productModule", ["getProductListAction"]),
    ...mapActions("deliveryModule", [
      "addDeliveryNotesAction",
      "getDeliveryNotesListAction",
    ]),
    printInvoice() {
      window.print();
    },
    currentDate() {
      return new Date();
    },
    successAlert() {
      this.$bvToast.toast("Payment Added Successfully", {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },
    errorAlert(error) {
      this.$bvToast.toast(error.response.data.message, {
        title: "Error",
        variant: "danger",
        solid: true,
      });
    },
    error(msg) {
      this.$swal({
        title: "Error!",
        text: msg || "Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getProductName(id) {
      for (let i = 0; i < this.productList.length; i++) {
        if (this.productList[i].id == id) {
          return this.productList[i].name;
        }
      }
    },

    doComplete() {
      let payload = {
        id: this.invoiceId.id,
        leadClientId: this.invoiceId.leadClientId,
        salesPersonId: this.invoiceId.salesPersonId,
        orderId: this.invoiceId.orderId,
        discount: this.invoiceId.discount,
        dueDate: this.invoiceId.dueDate,
        subTotal: this.invoiceId.subTotal,
        discount: this.invoiceId.discount,
        issuedDate: this.invoiceId.issuedDate,
        tax: this.invoiceId.tax,
        total: this.invoiceId.total,
        notes: this.invoiceId.notes,
        status: 2,
        invoiceType: this.invoiceId.invoiceType,
        paymentMethod: this.invoiceId.paymentMethod,
        invoiceNo: this.invoiceId.invoiceNo,
        isActive: true,
        invoiceItem: this.invoiceId.invoiceItem,

      }
      this.updateInvoicesAction(payload).then(() => {
        this.$swal({
          icon: "success",
          title: "Invoice completed successfully",
          customClass: {
            confirmButton: "btn btn-success",
          },
        })
        this.$router.push("/invoice/list");
      })
        .catch((error) => {
          this.error(error);
        });
    },
    CompleteInvoice() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to complete this invoice!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Complete it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.doComplete();
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Invoice is not completed :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },

    addDelivery() {
      this.addDeliveryNotesAction({
        invoiceId: this.id,
        itemId: this.deliveryProduct,
        deliveryDate: this.deliveryDate,
        note: this.deliveryNote,
        status: 0,
      })
        .then((response) => {
          console.log(response);
          this.successAlert();
          this.paymentAmount = "";
          this.paymentDate = "";
          this.$bvModal.hide("modal-delivery");
          this.getPaymentByIDAction(this.id);
          this.getInvoiceIdAction(this.id).then(() => {
            this.getSalesPersonListAction().then(() => {
              this.SalesPersonList.forEach((element) => {
                if (element.id == this.invoiceId.salesPersonId) {
                  this.genSP =
                    element.firstName + " " + element.lastName;
                }
              });
            });
          });
        })
        .catch((error) => {
          this.$bvModal.hide("modal-delivery");
          console.log(error);
          this.deliveryDate = "";
          this.deliveryNote = "";
          this.errorAlert();
          this.getPaymentByIDAction(this.id);
          this.getInvoiceIdAction(this.id).then(() => {
            this.getSalesPersonListAction().then(() => {
              this.SalesPersonList.forEach((element) => {
                if (element.id == this.invoiceId.salesPersonId) {
                  this.genSP =
                    element.firstName + " " + element.lastName;
                }
              });
            });
          });
        });
    },


    addPayment() {

      this.addPaymentAction({
        invoiceId: this.id,
        amountPaid: this.paymentAmount,
        settlementDate: this.paymentDate,
      })
        .then((response) => {
          console.log(response);
          this.successAlert();
          this.paymentAmount = "";
          this.paymentDate = "";
          this.$bvModal.hide("modal-payment");
          this.getPaymentByIDAction(this.id);
          this.getInvoiceIdAction(this.id).then(() => {
            this.getSalesPersonListAction().then(() => {
              this.SalesPersonList.forEach((element) => {
                if (element.id == this.invoiceId.salesPersonId) {
                  this.genSP =
                    element.firstName + " " + element.lastName;
                }
              });
            });
          });
        })
        .catch((error) => {
          this.$bvModal.hide("modal-payment");
          console.log(error);
          this.paymentAmount = "";
          this.paymentDate = "";
          this.errorAlert(error);
          this.getPaymentByIDAction(this.id);
          this.getInvoiceIdAction(this.id).then(() => {
            this.getSalesPersonListAction().then(() => {
              this.SalesPersonList.forEach((element) => {
                if (element.id == this.invoiceId.salesPersonId) {
                  this.genSP =
                    element.firstName + " " + element.lastName;
                }
              });
            });
          });
        });
    },
    downloadInvoice() {
      var element = document.getElementById("invoice-preview-card");
      var opt = {
        margin: [0, 0],
        filename: "invoice.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf().from(element).set(opt).save();
    },
  },
  async mounted() {
    await this.getProductListAction();
    await this.getInvoiceIdAction(this.id)
      .then(() => {
        this.invoiceId.invoiceItem.forEach((element) => {
          this.products.push({
            value: element.itemId,
            text: element.itemName,
          });
        });
        this.getSalesPersonListAction().then(() => {
          this.SalesPersonList.forEach((element) => {
            if (element.id == this.invoiceId.salesPersonId) {
              this.genSP =
                element.firstName + " " + element.lastName;
            }
          });
        });
        this.getDeliveryNotesListAction(this.id);
        this.getCustomersByIDAction(this.invoiceId.leadClientId);

        this.getPaymentByIDAction(this.invoiceId.id);
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style scoped>
#signaturename {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
}

#signature {
  width: 100%;
  border: 0px;
  border-bottom: 0.5px solid black;
  height: 0.5px;
}
</style>
<style lang="scss">
@media print {
  a[href]:after {
    content: none !important;
  }

  // Global Styles
  html,
  body {
    height: 99% !important;
  }

  .invoice-date-title {
    color: black !important;
  }

  * {
    color: black !important;
  }

  body {
    background-color: transparent !important;
    font-size: 17.5px !important;
    color: black !important;
  }

  .flatpickr-input {
    font-size: 17.5px !important;
    color: black !important;

  }

  .content-header {
    display: none;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            >.col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
